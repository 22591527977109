var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"placeholder":"Nome"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nome da Empresa","label-for":"company_name"}},[_c('validation-provider',{attrs:{"name":"Nome da Empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company_name","state":errors.length > 0 ? false:null,"placeholder":"Nome da Empresa"},model:{value:(_vm.data.company_name),callback:function ($$v) {_vm.$set(_vm.data, "company_name", $$v)},expression:"data.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"CPF","label-for":"cpf"}},[_c('validation-provider',{attrs:{"name":"CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],attrs:{"id":"taxId","state":errors.length > 0 ? false:null,"placeholder":"CPF"},model:{value:(_vm.data.cpf),callback:function ($$v) {_vm.$set(_vm.data, "cpf", $$v)},expression:"data.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"CNPJ","label-for":"cnpj"}},[_c('validation-provider',{attrs:{"name":"CNPJ","rules":"cnpj"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['##.###.###/####-##']),expression:"['##.###.###/####-##']"}],attrs:{"id":"cnpj","state":errors.length > 0 ? false:null,"placeholder":"CNPJ"},model:{value:(_vm.data.cnpj),callback:function ($$v) {_vm.$set(_vm.data, "cnpj", $$v)},expression:"data.cnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Registradora","label-for":"register"}},[_c('b-form-checkbox-group',{attrs:{"id":"register","options":['TAG', 'CERC'],"switches":""},model:{value:(_vm.data.register),callback:function ($$v) {_vm.$set(_vm.data, "register", $$v)},expression:"data.register"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }