<template>
  <b-container class="d-flex justify-content-center">
    <b-col
      v-if="id"
      md="4"
    >
      <b-card>
        <p> Usuário Relacionados: </p>
        <b-list-group
          v-for="item in request.users"
          :key="item.id"
          flush
        >
          <b-list-group-item>{{ item.name }}</b-list-group-item>
        </b-list-group></b-card>
    </b-col>
    <b-col md="8">
      <b-card :title="id ? 'Editar Grupo' : 'Criar Grupo'">
        <validation-observer
          #default="{invalid}"
          ref="simpleRules"
        >
          <group-form :data="request" />
          <b-row>
            <b-col>
              <b-button
                style="width: 100%;"
                variant="primary"
                :disabled="invalid"
                @click="createGroup"
              >
                {{ id ? 'Editar' : 'Criar' }}
              </b-button>
            </b-col>

            <b-col>
              <b-button
                style="width: 100%;"
                variant="primary"
                :to="{ name: 'group' }"
              >
                Voltar
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-container>
</template>

<script>
import {
    BButton,
    BCard,
    BListGroup,
    BListGroupItem,
    BContainer,
    BCol,
    BRow,
} from 'bootstrap-vue';

import {
    ValidationObserver,
} from 'vee-validate';
import '@validations';

import GroupService from '@/service/credit_platform/groups';

import GroupForm from './components/GroupForm.vue';
import { callLoading } from '@/service/utilities';

export default {
    components: {
        GroupForm,
        BContainer,
        BCard,
        BListGroup,
        BListGroupItem,
        BCol,
        ValidationObserver,
        BButton,
        BRow,
    },

    data() {
        return {
            request: {
                name: '',
                companyName: '',
                document: '',
            },
            id: this.$route.params.id,
            allEmailsRegistred: [],
        };
    },

    async mounted() {
        if (this.id) {
            const { data } = await GroupService.getGroupById(this.id);
            this.request = {
                ...data,
                taxId: data.cnpj || data.cpf,
                register: data.group_register.map(item => item.register),
            };
        }
    },

    methods: {
        modalError(text) {
            this.$swal({
                icon: 'error',
                text,
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
            });
        },

        modalSuccess(text) {
            this.$swal({
                icon: 'success',
                text,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            });
        },
        async confirmAnAction(message) {
            try {
                const response = await this.$bvModal
                    .msgBoxConfirm(message, {
                        cancelVariant: 'outline-secondary',
                        centered: true,
                        okTitle: 'Sim',
                        cancelTitle: 'Não',
                    });
                return response;
            } catch {
                return false;
            }
        },
        async createGroup() {
            const isConfirmed = await this.confirmAnAction(`Você realmente deseja ${this.id ? 'editar' : 'criar'} esse usuário`);

            if (!isConfirmed) {
                return;
            }
            callLoading(true);
            const removeSymbolsTaxId = document => (document ? document.replace(/[^a-zA-Z0-9]/g, '') : undefined);

            const {
                name, company_name, cnpj, cpf, register,
            } = this.request;

            const request = {
                name, company_name, cnpj: removeSymbolsTaxId(cnpj), cpf: removeSymbolsTaxId(cpf), register,
            };

            const { status } = this.id ? await GroupService.update(request, this.id) : await GroupService.store(request);
            callLoading(false);

            if (status === 200) {
                this.modalSuccess(`Usuário ${this.id ? 'Editado' : 'Criado'} com Sucesso`);
                this.$router.push('/plataforma-credito/grupo');
            } else {
                this.modalError('Não foi possível criar esse usuário');
            }
        },
    },
};
</script>
