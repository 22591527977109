<template>
  <b-form
    class="needs-validation"
    @submit.prevent
  >
    <b-form-group
      label="Nome"
      label-for="name"
    >
      <validation-provider
        #default="{ errors }"
        name="Nome"
        rules="required"
      >

        <b-form-input
          id="name"
          v-model="data.name"
          :state="errors.length > 0 ? false:null"
          placeholder="Nome"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Nome da Empresa"
      label-for="company_name"
    >
      <validation-provider
        #default="{ errors }"
        name="Nome da Empresa"
        rules="required"
      >

        <b-form-input
          id="company_name"
          v-model="data.company_name"
          :state="errors.length > 0 ? false:null"
          placeholder="Nome da Empresa"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="CPF"
      label-for="cpf"
    >
      <validation-provider
        #default="{ errors }"
        name="CPF"
        rules="required|cpf"
      >

        <b-form-input
          id="taxId"
          v-model="data.cpf"
          v-mask="['###.###.###-##']"
          :state="errors.length > 0 ? false:null"
          placeholder="CPF"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="CNPJ"
      label-for="cnpj"
    >
      <validation-provider
        #default="{ errors }"
        name="CNPJ"
        rules="cnpj"
      >

        <b-form-input
          id="cnpj"
          v-model="data.cnpj"
          v-mask="['##.###.###/####-##']"
          :state="errors.length > 0 ? false:null"
          placeholder="CNPJ"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Registradora"
      label-for="register"
    >

      <b-form-checkbox-group
        id="register"
        v-model="data.register"
        :options="['TAG', 'CERC']"
        switches
      />

    </b-form-group>

  </b-form>
</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BRow,
    BCol,
} from 'bootstrap-vue';

import {
    ValidationProvider,
} from 'vee-validate';

import '@validations';

const permissionsOptions = [
    { value: 2, text: 'Cadastrar Usuários' },
    { value: 3, text: 'Cadastrar Estabelecimentos' },
    { value: 4, text: 'Criar Opt Ins' },
    { value: 5, text: 'Atualizar Opt Ins' },
    { value: 6, text: 'Consulta Opt Ins' },
    { value: 7, text: 'Rejeitar Opt Ins' },
    { value: 8, text: 'Criar Opt Out' },
    { value: 9, text: 'Criar Averbações' },
    { value: 10, text: 'Atualizar Avebações' },
    { value: 11, text: 'Buscar Averbações' },
    { value: 12, text: 'Consulta Agenda' },
    { value: 13, text: 'Visualizar Estatísticas' },
    { value: 14, text: 'Visualizar Extrato' },
].sort((a, b) => a.text.localeCompare(b.text));

export default {
    components: {
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BFormCheckboxGroup,
        ValidationProvider,
        BRow,
        BCol,
    },

    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            permissionsOptions,
            allEmailsRegistred: [],
        };
    },
};
</script>
